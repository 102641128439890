<template>
	<div>
		<ClientOnly>
			<Disclosure
				v-slot="{ close }"
				as="nav"
				class="fixed top-0 z-30 w-full bg-white shadow lg:hidden dark:bg-mx-green-800"
			>
				<div class="px-4">
					<div class="flex justify-between h-[6vh]">
						<div class="flex items-center justify-between flex-1 sm:items-stretch">
							<NavMobileHamburgerMenu color="text-black dark:text-white" />
						</div>
						<div class="absolute -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
							<BrandingLogo class="h-8" />
							<!--
								<Avatar v-if="showAvatar" />
							-->
						</div>
					</div>
				</div>
				<NavDrawer @nav-link-clicked="handleNavigationClick(close)" />
			</Disclosure>
		</ClientOnly>
	</div>
</template>

<script setup lang="ts">
import { Disclosure } from '@headlessui/vue'

// const showAvatar = !isAccountPortal.value

const handleNavigationClick = (close: any) => {
	close()
}

</script>
