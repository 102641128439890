<template>
	<div class="py-4">
		<h4
			v-if="title"
			class="my-4 text-xs text-mx-gray-400"
		>
			{{ title }}
		</h4>
		<nav class="flex flex-col flex-1">
			<ul
				role="list"
				class="flex flex-col flex-1 gap-y-7"
			>
				<li>
					<ul
						role="list"
						class="-mx-2 space-y-1"
					>
						<li
							v-for="item in items"
							:key="item.name"
							class="group"
						>
							<template v-if="!item.children">
								<NuxtLink
									v-if="item.href"
									:to="item.href"
									:class="[
										isCurrentItem(item.href) ? 'text-mx-orange' : 'text-mx-gray-500 dark:text-mx-gray-400 hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 dark:hover:text-mx-gray-300',
										'transition-all duration-100 group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
									]"
									@click="handleNavigationClick"
								>
									<component
										:is="item.icon"
										:id="item.name"
										:class="[
											'w-6 h-6 shrink-0 transition-all duration-100',
											isCurrentItem(item.href) ? 'text-mx-orange' : 'group-hover:bg-mx-gray-100 dark:group-hover:bg-mx-green-600 dark:group-hover:text-mx-gray-300'
										]"
										aria-hidden="true"
									/>
									<div :class="['text-nowrap transition-all', showText ? 'visible' : 'invisible']">
										{{ item.name }}
									</div>
								</NuxtLink>
								<button
									v-if="item.action"
									class="flex w-full p-2 text-sm font-semibold leading-6 transition-all duration-100 rounded-md hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 dark:hover:text-mx-gray-300 group gap-x-3 text-mx-gray-500 dark:text-mx-gray-400"
									@click="item.action"
								>
									<component
										:is="item.icon"
										class="w-6 h-6 shrink-0 dark:group-hover:text-mx-gray-300"
										aria-hidden="true"
									/>
									<div :class="['text-nowrap transition-all', showText ? 'visible' : 'invisible']">
										{{ item.name }}
									</div>
								</button>
							</template>
							<Disclosure
								v-else
								v-slot="{ open }"
								:default-open="expandChildren(item)"
								as="div"
							>
								<DisclosureButton
									v-if="item.href || item.function"
									:class="[
										isCurrentItem(item.href) ? 'text-mx-orange' : 'text-mx-gray-500 dark:text-mx-gray-400 hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 dark:hover:text-mx-gray-300',
										'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold',

									]"
								>
									<component
										:is="item.icon"
										:class="[
											'w-6 h-6 shrink-0',
											isCurrentItem(item.href) ? 'text-mx-orange' : 'dark:hover:text-mx-gray-300 dark:hover:bg-mx-green-600',
										]"
										aria-hidden="true"
									/>
									{{ item.name }}
									<ChevronRightIcon
										:class="[
											open ? '-rotate-90' : 'rotate-90',
											isCurrentItem(item.href) ? 'stroke-mx-orange fill-mx-orange' : 'dark:group-hover:text-mx-gray-300',
											'ml-auto h-7 w-7 shrink-0 transition-all duration-100 text-mx-gray-500 dark:text-mx-gray-400'
										]"
										aria-hidden="true"
									/>
								</DisclosureButton>
								<DisclosurePanel
									as="ul"
									class="px-2 mt-1"
								>
									<li
										v-for="subItem in item.children"
										:key="subItem.name"
										class="pt-1 -mx-2 group first-of-type:pt-0"
									>
										<div class="transition-all duration-100 border-b-2 hover:rounded-md dark:border-mx-green-700">
											<NuxtLink
												v-if="subItem.href"
												:to="`${item.href}${subItem.href}`"
												:class="[
													isCurrentItem(`${item.href}${subItem.href}`) ? 'text-mx-orange dark:text-mx-orange' : 'dark:hover:text-mx-gray-300 text-mx-gray-500 dark:text-mx-gray-400',
													'transition-all ml-12 duration-100 block rounded-md p-2 text-sm font-semibold leading-6 hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 dark:border-mx-green-700 dark:hover:text-mx-gray-300'
												]"
												@click="handleNavigationClick"
											>
												{{ subItem.name }}
											</NuxtLink>
											<button
												v-if="subItem.action"
												class="flex w-full p-2 text-sm font-semibold leading-6 transition-all duration-100 rounded-md hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 dark:hover:text-mx-gray-300 group gap-x-3 text-mx-gray-500 dark:text-mx-gray-400"
												@click="handleNavigationClick(subItem.action(subItem.id))"
											>
												<component
													:is="subItem.icon"
													class="w-6 h-6 shrink-0 dark:group-hover:text-mx-gray-300"
													aria-hidden="true"
												/>
												<div :class="['transition-all text-left', showText ? 'visible' : 'invisible']">
													{{ subItem.name }}
												</div>
											</button>
										</div>
									</li>
								</DisclosurePanel>
							</Disclosure>
						</li>
					</ul>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import type { NavItemSection, NavItem } from '@/components/nav/types'
import { useRoute } from 'vue-router'
import { CURRENT_ITEM_OVERRIDE } from '@/constants/navigation/account'

const route = useRoute()

const props = defineProps({
	navSection: {
		type: Object as PropType<NavItemSection>,
		required: true
	},
	showText: {
		type: Boolean,
		default: true
	}
})

const { navSection } = toRefs(props)

const title = computed(() => navSection.value?.title || '')
const items = computed<NavItem[]>(() => navSection.value?.items || [])

const expandChildren = (item: NavItem) => {
	if (item.href) {
		return route.path.includes(item.href)
	}
}

const isCurrentItem = (href: String) => {
	return href === route.path || href === CURRENT_ITEM_OVERRIDE
}
const emit = defineEmits([ 'nav-link-clicked' ])
const handleNavigationClick = (func?: Function) => {
	emit('nav-link-clicked')
	if (func && typeof func === 'function') {
		func()
	}
}

</script>
