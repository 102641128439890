<template>
	<ClientOnly>
		<div class="-mx-2 -mt-2">
			<button
				class="flex w-full p-2 text-sm font-semibold leading-6 rounded-md text-mx-gray-500 dark:text-mx-gray-400 hover:bg-mx-gray-100 dark:hover:bg-mx-green-600 dark:hover:text-mx-gray-300 group gap-x-3"
				aria-label="Theme"
				@click="handleThemeChange"
			>
				<span class="flex-none w-6 h-6 rounded-full flex-nowrap focus:outline-none focus:ring-2 focus:ring-mx-orange">
					<MoonIcon
						v-if="colorMode.preference === 'light'"
						class="w-6 h-6 shrink-0"
						aria-hidden="true"
					/>
					<SunIcon
						v-else
						class="w-6 h-6 shrink-0"
						aria-hidden="true"
					/>
				</span>
				<span :class="['block text-nowrap transition-all', showText ? 'visible' : 'invisible' ]">
					{{ colorMode.preference === 'light' ? 'Dark Mode' : 'Light Mode' }}
				</span>
			</button>
		</div>
	</ClientOnly>
</template>

<script setup lang="ts">
import { MoonIcon, SunIcon } from '@heroicons/vue/24/outline'

const colorMode = useColorMode()

defineProps({
	showText: {
		type: Boolean,
		default: true
	}
})

const handleThemeChange = () => {
	if (colorMode.preference === 'dark') {
		colorMode.preference = 'light'
	} else {
		colorMode.preference = 'dark'
	}
}
</script>
